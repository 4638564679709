<template>
    <div class="f1 apps-page w-full dF fC f1 px-4">
        <div class="dF aC">
            <div class="dF aC f1">
                <h3>Projects</h3>
            </div>
            <div class="dF aC">
                <a-select
                    placeholder="Bulk Action"
                    style="width: 175px"
                    class="text-primary"
                    v-model="bulkAction"
                    @select="onBulkActionChange"
                    :allowClear="bulkAction !== 'Bulk Action'"
                >
                    <a-select-option value="bulk-suspend">
                        Bulk Lock
                    </a-select-option>
                    <a-select-option value="bulk-unlock">
                        Bulk Unlock
                    </a-select-option>
                    <a-select-option value="bulk-delete">
                        Bulk Delete
                    </a-select-option>
                </a-select>
            </div>
        </div>
        <a-card :bodyStyle="{ padding: 0 }" class="my-3">
            <div>
                <a-table
                    :rowKey="(e) => e.key"
                    :columns="columns"
                    :row-selection="rowSelection"
                    :dataSource="projects"
                    :loading="loading"
                    :pagination="{
                        current: currentPage,
                        total: totalProjects,
                        pageSize: pageSize,
                    }"
                    @change="handleChange"
					:scroll="{ x: 1000 }"
                >
                    <div slot="readableId" slot-scope="instance">
                        {{ instance.readableId }}
                    </div>
                    <div slot="name" slot-scope="instance">
                        {{ instance.name }}
                    </div>
                    <div slot="projectType" slot-scope="instance">
                        {{ instance.projectType }}
                    </div>

                    <div slot="productType" slot-scope="instance">
                        {{
                            instance.productType === "lowrise"
                                ? "Low Rise"
                                : "High Rise"
                        }}
                    </div>
                    <div slot="projectOwner" slot-scope="instance">
						<span v-if="instance.owner"> {{ instance.owner.email }}</span>
                    </div>
					<div slot="billingOwner" slot-scope="instance">
						<span v-if="instance.billingOwner"> {{ instance.billingOwner.email }}</span>
                    </div>
					<div slot="partner" slot-scope="instance" class="text-center">
						<span v-if="instance.partner"
                            class="project-button"
                            style="background-color: #dddcfc;color: #7874e9"
                            >{{ instance.partner.companyName }}</span
                        >
                    </div>
                    <div slot="planType" slot-scope="instance">
                        {{ instance.package && instance.package.name }}
                    </div>
                    <div
                        slot="members"
                        slot-scope="instance"
                        class="dF aC"
                        style="justify-content: center"
                    >
                        <a-avatar
                            style="margin-left: -10px"
                            shape="circle"
                            size="default"
                            :class="$style.avatar"
                            v-for="user in first3Users(instance)"
                            :key="user.id"
                            :style="{
                                'background-color':
                                    user.id === 'id'
                                        ? '#9693e7'
                                        : 'var(--primary)',
                            }"
                            >{{
                                user.firstName
                                    ? user.id !== "id"
                                        ? user.firstName[0].toUpperCase()
                                        : user.firstName
                                    : "--"
                            }}</a-avatar
                        >
                    </div>
                    <div
                        slot="createdAt"
                        slot-scope="instance"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(instance.createdAt) }}
                    </div>
					<div
                        slot="validUntil"
                        slot-scope="instance"
                        style="color: var(--med-gray)"
                    >
						<span v-if="instance.isDemo">
							{{ Math.abs(moment().diff(moment(instance.validUntil), 'days')) }} Days left
						</span>
                    </div>
                    <div slot="status" slot-scope="instance">
                        <span v-if="instance.deleted">Deleted</span>
                        <span v-else-if="instance.susspended"> Locked </span>
                        <span v-else> Active</span>
                    </div>
                    <div
                        slot="actions"
                        slot-scope="instance"
                        class="dF"
                        style="justify-content: flex-end"
                    >
                        <a-popover
                            trigger="hover"
                            placement="bottomRight"
                            overlayClassName="popoverStyle"
                        >
                            <div slot="content">
                                <div
                                    class="popoverContent dF aC"
                                    @click="editProject(instance)"
                                >
                                    Edit Project
                                </div>
								<div
                                    class="popoverContent dF aC"
                                    @click="selectedProject = instance; cloneModal = true;"
                                >
                                    Clone Project
                                </div>
                                <div
                                    v-if="!instance.susspended"
                                    class="popoverContent dF aC"
                                    @click="
                                        selectedProject = instance;
                                        lockModal = true;
                                    "
                                >
                                    Lock Project
                                </div>
                                <div
                                    v-else
                                    class="popoverContent dF aC"
                                    @click="
                                        selectedProject = instance;
                                        unlockModal = true;
                                    "
                                >
                                    Unlock Project
                                </div>
                                <div
                                    class="popoverContent dF aC"
                                    @click="
                                        selectedProject = instance;
                                        deleteModal = true;
                                    "
                                >
                                    Delete Project
                                </div>
                            </div>
                            <div class="more-option-icon mr-4">
                                <a-icon style="line-height: 40px" type="more" />
                            </div>
                        </a-popover>
                    </div>
                </a-table>
            </div>
        </a-card>
        <a-modal
            v-model="unlockModal"
            :title="`Unlock ${selectedProjectsName ? 'Projects' : 'Project'}`"
            :ok-text="`UNLOCK ${selectedProjectsName ? 'PROJECTS' : 'PROJECT'}`"
            @cancel="resetData"
            @ok="unlockProject"
        >
            <p>
                Unlock
                {{ selectedProjectsName ? "these projects" : "this project" }}
                <strong>
                    {{ selectedProjectsName || selectedProject.name }} </strong
                >.
            </p>
        </a-modal>
        <a-modal
            v-model="lockModal"
            :title="`Lock ${selectedProjectsName ? 'Projects' : 'Project'}`"
            :ok-text="`LOCK ${selectedProjectsName ? 'PROJECTS' : 'PROJECT'}`"
            @cancel="resetData"
            @ok="lockProject"
        >
            <p>
                All users will be blocked from accessing
                {{ selectedProjectsName ? "these projects" : "this project" }}
                <strong>
                    {{ selectedProjectsName || selectedProject.name }} </strong
                >.
            </p>
			<a-checkbox v-if="!selectedProjectsName" v-model="chargeForSuspension">
                <span>
                    Charge for suspension
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox, one invoice will be generated with $249(USD) amount.
                        </template>
                        <a-icon
                            type="question-circle"
                            style="font-size: 12px"
                        />
                    </a-tooltip>
                </span>
            </a-checkbox>
        </a-modal>
        <a-modal
            v-model="deleteModal"
            :title="`Delete Your ${
                selectedProjectsName ? 'Projects' : 'Project'
            }`"
            ok-text="DELETE"
            @cancel="resetData"
            @ok="deleteProject"
            :ok-button-props="{ props: { disabled: !deleteAccepted } }"
        >
            <p>
                You are about to delete your
                {{ selectedProjectsName ? "projects" : "project" }}
                <strong>
                    {{ selectedProjectsName || selectedProject.name }} </strong
                >. All your data will be deleted.
            </p>
            <p>
                <b
                    >Consider downloading your data before deleting a
                    {{ selectedProjectsName ? "projects" : "project" }}.</b
                >
            </p>
            <a-checkbox v-model="downloadData">
                <span>
                    DOWNLOAD DATA (Your data will consist of all your leads and
                    all your completed transactions.)
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox an email with a link
                            will be send to you that will allow you to download
                            your data.
                        </template>
                        <a-icon
                            type="question-circle"
                            style="font-size: 12px"
                        />
                    </a-tooltip>
                </span>
            </a-checkbox>
            <br />
            <br />
            <a-checkbox v-model="deleteAccepted">
                I understand that deleting my
                {{ selectedProjectsName ? "projects" : "project" }} can’t be
                undone and all my data will be deleted.
            </a-checkbox>
        </a-modal>
		<a-modal
            v-model="cloneModal"
            title="Clone Project"
            ok-text="Clone"
            @cancel="resetData"
            @ok="cloneProject"
        >
			<a-form-model ref="cloneProjectDetails" :model="cloneProjectDetails">
                <a-form-model-item
                    label="Clone Project Name"
                    prop="name"
                    required
                    :rules="req('Please enter new clone project name')"
                >
					<a-input
                        v-model="cloneProjectDetails.name"
                        placeholder="clone project name"
                        size="large"
                    >
                    </a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import _ from "lodash";

export default {
    data() {
		return {
			sortedInfo: {
				key: "createdAt",
				order: "DESC",
			},
			bulkAction: "Bulk Action",
			selectedRowKeys: [],
			selectedRowIds: [],
			instances: [],
			loading: false,
			currentPage: 1,
			totalProjects: 0,
			pageSize: 10,
			lockModal: false,
			unlockModal: false,
			deleteModal: false,
			cloneModal: false,
			deleteAccepted: false,
			downloadData: false,
			chargeForSuspension: false,
			selectedProject: {},
			selectedProjects: [],
			cloneProjectDetails: {
				isDemo: true,
				name: null
			}
		};
    },
    computed: {
        search() {
            return this.$store.state.searchFilter.toLowerCase();
        },
        columns() {
            return [
                {
                    title: "Project ID",
                    key: "readableId",
                    scopedSlots: { customRender: "readableId" },
                    sorter: true,
                },
                {
                    title: "Project Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: true,
                },
                {
                    title: "Project Type",
                    key: "projectType",
                    scopedSlots: { customRender: "projectType" },
                    sorter: true,
                },
                {
                    title: "Product Type",
                    key: "productType",
                    scopedSlots: { customRender: "productType" },
                    sorter: true,
                },
                {
                    title: "Project Owner",
                    key: "owner.email",
                    scopedSlots: { customRender: "projectOwner" },
                    sorter: true,
                },
				{
                    title: "Billing Owner",
                    key: "billingOwner.email",
                    scopedSlots: { customRender: "billingOwner" },
                    sorter: true,
                },
				{
                    title: "Partner",
                    key: "partner.companyName",
                    scopedSlots: { customRender: "partner" },
                    sorter: true,
                },
                {
                    title: "Plan Type",
                    key: "package.name",
                    scopedSlots: { customRender: "planType" },
                    sorter: true,
                },
                {
                    title: "Members",
                    key: "members",
                    scopedSlots: { customRender: "members" },
                },
                {
                    title: "Date Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: true,
                },
                {
                    title: "Status",
                    key: "susspended",
                    scopedSlots: { customRender: "status" },
                    sorter: true,
                },
				{
                    title: "Demo Project",
                    key: "updatedAt",
                    scopedSlots: { customRender: "validUntil" },
                    sorter: true,
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
					width: "2%",
                },
            ];
        },
        projects() {
            return this.instances;
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },

        selectedProjectsName() {
            return this.selectedProjects.map((p) => p.name).join(", ");
        },
    },
    watch: {
        search() {
            this.searchDebounce();
        },
    },
    created() {
        this.getTotalProjectCount();
        this.getProjects();
    },

    methods: {
		moment,
        ...mapMutations(["SET_PROJECT", "UPDATE_SEARCH"]),

		req: (msg) => ({ required: true, message: msg }),

        searchDebounce: _.debounce(function () {
			this.currentPage = 1
            this.getProjects();
            this.getTotalProjectCount();
        }, 1000),

        async getTotalProjectCount() {
            try {
                let searchQuery = "?deleted=false";
                if (this.search) {
                    searchQuery += `&_q=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `super-admin/instances/count${searchQuery}`
                );
                this.totalProjects = data;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching project count. Please try again!"))
				}
            }
        },
        async getProjects() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&_q=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/super-admin/instances?deleted=false&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
                );
                this.instances = data.map((x, i) => {
                    const instance = {
                        ...x,
                        allChild: x.children,
                        key: i,
                    };
                    delete instance.children;
                    return instance;
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching project list. Please try again!"))
				}
            }
            this.loading = false;
        },

        onBulkActionChange(bulkAction) {
			if (!this.selectedRowKeys.length) {
                this.$message.warn("Please select projects first");
                this.bulkAction = "Bulk Action";
                return;
            }
            this.bulkAction = bulkAction;
            if (bulkAction === "bulk-delete") {
                this.deleteModal = true;
                this.selectedProjects = this.instances.filter((i) =>
                    this.selectedRowIds.includes(i.id)
                );
            } else if (bulkAction === "bulk-suspend") {
                this.lockModal = true;
                this.selectedProjects = this.instances.filter((i) =>
                    this.selectedRowIds.includes(i.id)
                );
            } else if (bulkAction === "bulk-unlock") {
                this.unlockModal = true;
                this.selectedProjects = this.instances.filter(
                    (i) => this.selectedRowIds.includes(i.id) && i.susspended
                );
            }
        },

        performBulkAction(bulkAction) {
            if (!this.selectedRowKeys.length) {
                this.$message.warn("Please select projects first");
                this.bulkAction = "Bulk Action";
                return;
            }
            this.bulkAction = bulkAction;

            if (bulkAction === "bulk-delete") {
                const alreadyDeleted = [];
                this.projects.forEach((p) => {
                    if (p.deleted && this.selectedRowIds.includes(p.id)) {
                        alreadyDeleted.push(p.name);
                    }
                });

				const deleteProjectNo = alreadyDeleted.length

                if (deleteProjectNo) {
                    this.$message.warn(
                        `${deleteProjectNo > 1 ? 'Projects' : 'Project'} (${alreadyDeleted.join()}) ${deleteProjectNo > 1 ? 'are' : 'is'} already deleted.`
                    );
                }

                if (deleteProjectNo === this.selectedRowKeys.length) {
                    return this.resetData();
                }
            } else if (bulkAction === "bulk-suspend") {
                const alreadyLocked = [];
                this.projects.forEach((p) => {
                    if (p.susspended && this.selectedRowIds.includes(p.id)) {
                        alreadyLocked.push(p.name);
                    }
                });

				const lockProjectNo = alreadyLocked.length

                if (lockProjectNo) {
                    this.$message.warn(
                        `${lockProjectNo > 1 ? 'Projects' : 'Project'} (${alreadyLocked.join()}) ${lockProjectNo > 1 ? 'are' : 'is'} already locked.`
                    );
                }

                if (lockProjectNo === this.selectedRowKeys.length) {
                    return this.resetData();
                }
            } else if (bulkAction === "bulk-unlock") {
                const alreadyUnlocked = [];
                this.projects.forEach((p) => {
                    if (!p.susspended && !p.deleted && this.selectedRowIds.includes(p.id)) {
                        alreadyUnlocked.push(p.name);
                    }
                });

				const unlockProjectNo = alreadyUnlocked.length

                if (unlockProjectNo) {
                    this.$message.warn(
                        `${unlockProjectNo > 1 ? 'Projects' : 'Project'} (${alreadyUnlocked.join()}) ${unlockProjectNo > 1 ? 'are' : 'is'} already unlocked.`
                    );
                }

                if (unlockProjectNo === this.selectedRowKeys.length) {
                    return this.resetData();
                }
            }

            this.$api
                .post(`/instances/${bulkAction}`, {
                    instances: this.selectedRowIds,
                    downloadData: this.downloadData,
                })
                .then(() => {
                    const action =
                        bulkAction === "bulk-suspend"
                            ? "Locked"
                            : bulkAction === "bulk-unlock"
                            ? "Unlocked"
                            : "Deleted";
                    this.$message.success(
                        `Successfully ${action} selected valid projects.`
                    );
                    this.getProjects();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while performing ${bulkAction} action. Please try again!`));
					}
                })
                .finally(() => {
                    this.resetData();
                });
        },
        getDate(date) {
            return moment(date).format("D/M/YYYY");
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowIds = selectedRows.map((i) => i.id);
            this.selectedRowKeys = selectedRowKeys;
        },
        editProject(instance) {
            this.SET_PROJECT(instance);
            this.$router.push(`/projects/${instance.id}`);
        },
        lockProject() {
            if (this.bulkAction === "bulk-suspend") {
                return this.performBulkAction(this.bulkAction);
            }
            this.$api
                .post(`/instances/${this.selectedProject.id}/suspend`, { chargeForSuspension: this.chargeForSuspension })
                .then(() => {
                    this.$message.success(
                        "Successfully Locked the selected project"
                    );
                    this.getProjects();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while locking project. please try again!"));
					}
                })
                .finally(() => {
                    this.resetData();
                });
        },
        deleteProject() {
            if (this.bulkAction === "bulk-delete") {
                return this.performBulkAction(this.bulkAction);
            }
            this.$api
                .delete(
                    `/instances/${this.selectedProject.id}/admin-delete?downloadData=${this.downloadData}`
                )
                .then(() => {
                    this.$message.success(
                        "Successfully Deleted the selected project"
                    );
                    this.getProjects();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while deleting project. Please try again!"));
					}
                })
                .finally(() => {
                    this.resetData();
                });
        },

        unlockProject() {
            if (this.bulkAction === "bulk-unlock") {
                return this.performBulkAction(this.bulkAction);
            }
            this.$api
                .post(`/instances/${this.selectedProject.id}/unlock`)
                .then(() => {
                    this.$message.success("Successfully Unlocked the project");
                    this.getProjects();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while unlocking project. please try again!"));
					}
                })
                .finally(() => {
                    this.resetData();
                });
        },

		cloneProject() {
			this.$refs.cloneProjectDetails.validate((valid) => {
				if (valid) {
					this.loading = true;
					this.$api.post(`super-admin/instances/${this.selectedProject.id}/clone`, { ... this.cloneProjectDetails })
						.then(() => {
							this.$message.success("Successfully trigger clone project process.");
							this.resetData();
						})
						.catch((err) => {
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, `Error while cloning project. Please Try Again!`));
							}
						}).finally(() => this.loading = false);
				}
			})
		},

        handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
				this.selectedRowKeys = [];
				this.selectedRowIds = [];
            }
            if (sorter && sorter.column) {
                if (
                    sorter.column.title === "Status" &&
                    sorter.order !== "descend"
                ) {
                    sorter.columnKey = "susspended";
                }
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getProjects();
        },

        first3Users(instance) {
            let members = instance.users.filter((u) => u.confirmed);
            const extraMembers = members.length > 3 ? members.length - 3 : 0;
            members = members.filter((u, i) => i < 3);
            if (extraMembers) {
                members.push({ firstName: `+${extraMembers}`, id: "id" });
            }
            return members;
        },

		resetData() {
			this.lockModal = false;
			this.unlockModal = false;
			this.deleteModal = false;
			this.downloadData = false;
			this.deleteAccepted = false;
			this.chargeForSuspension = false;
			this.loading = false;
			this.cloneModal = false;
			this.cloneProjectDetails = { isDemo: true, name: "" };
			this.selectedProject = {};
			this.selectedRowKeys = [];
			this.selectedRowIds = [];
			this.selectedProjects = [];
			this.bulkAction = "Bulk Action";
		},
    },

    beforeDestroy() {
        this.UPDATE_SEARCH("");
    },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.popoverContent {
    height: max-content;
    line-height: 30px;
    padding: 0 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}

.project-button {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 4px 8px;
}
</style>

<style lang="scss">
li.ant-select-dropdown-menu-item {
    height: max-content;
    line-height: 30px;
    background-color: white !important;
}
li.ant-select-dropdown-menu-item-active {
    background-color: var(--off-white-light) !important;
}
li.ant-select-dropdown-menu-item:hover {
    color: var(--primary);
    cursor: pointer;
}

body .ant-select-dropdown-menu-item {
    padding: 5px 10px;
}
.popoverStyle .ant-popover-inner-content {
    padding: 5px;
    background-color: white;
    border-radius: 20px;
}
</style>
